// Here you can add other styles
body {
    @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400&display=swap");
}
body {
    font-family: "Kanit" !important;
    background-color: #f3f3f3;
}
.bottom-shadow {
    box-shadow: 0 4px 6px -6px #222;
}
.btn {
    margin: 0 0.3em;
}
.btn-primary {
    background-color: #0076fe;
    border-color: #0076fe;
    color: #ffffff;
}
.btn-success {
    background-color: #66bf0a;
    border-color: #66bf0a;
    color: #ffffff;
}
.btn-warning {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #ffffff;
}
.btn-danger {
    background-color: #fe1602;
    border-color: #fe1602;
    color: #ffffff;
}
.color-primary {
    color: #0076fe;
}
.color-success {
    color: #66bf0a;
}
.color-warning {
    color: #ffc107;
}
.color-danger {
    color: #fe1602;
}
.c-header .c-subheader {
    border: none !important;

    .c-header-nav {
        min-height: 60px;
    }
}
.icon-button {
    background: none;
    border: none;
    padding: 0px 4px;
}
.icon-button:hover {
    opacity: 0.5;
}
.logo-img {
    max-height: 45px;
    max-width: 45px;
}
.nav-tabs .nav-link {
    cursor: pointer;
}
.scale-hover {
    position: relative;
    z-index: 100;
}
.scale-hover:hover {
    z-index: 120;
    transition: transform 0.5s;
    transform: scale(1.25);
}
.table th,
.table td {
    padding: 0.5rem;
}
.table-sm td,
.table-sm th {
    padding: 0.3rem;
}
.text-button {
    color: #fe1602;
    cursor: pointer;
    padding: 0.375rem;
    margin: 0.3em;
}
.text-header {
    display: inline;
    margin: 0px;
}
