// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";

@import "_sidebar";
// If you want to add something do it here
@import "_custom";
